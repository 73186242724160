import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div align="center">
  <iframe style={{
        "width": "640px",
        "height": "360px",
        "borderWidth": "0px 0px 0px 0px",
        "borderStyle": "solid solid solid solid",
        "borderColor": "black black black black"
      }} src="https://youtube.com/embed/TbgzSOgM-p0?autoplay=1&loop=1&playlist=TbgzSOgM-p0" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <h3 {...{
      "id": "xtts-the-open-foundation-speech-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#xtts-the-open-foundation-speech-model",
        "aria-label": "xtts the open foundation speech model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`XTTS the Open, Foundation Speech Model`}</h3>
    <p>{`Coqui is more than proud to announce the release of `}<strong parentName="p">{`XTTS`}</strong>{`, the first generative, text-to-speech
foundation model that is both open `}<em parentName="p">{`and`}</em>{` production-quality.`}</p>
    <h3 {...{
      "id": "try-xtts-now",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#try-xtts-now",
        "aria-label": "try xtts now permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Try XTTS Now!`}</h3>
    <table>
      <tr style={{
        "border": "none"
      }}>
        <td>
          <div align="center">
            <Link to="https://github.com/coqui-ai/tts" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "255px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "26.8%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "IMAGE",
                    "title": "IMAGE",
                    "src": "/static/e6999cfcb156f7fb3fe812cbe215f909/ecc5c/blog-open-xtts-github.png",
                    "srcSet": ["/static/e6999cfcb156f7fb3fe812cbe215f909/43fa5/blog-open-xtts-github.png 250w", "/static/e6999cfcb156f7fb3fe812cbe215f909/ecc5c/blog-open-xtts-github.png 255w"],
                    "sizes": "(max-width: 255px) 100vw, 255px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
          </div>
        </td>
        <td>
          <div align="center">
            <Link to="https://huggingface.co/spaces/coqui/XTTS" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "256px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "26.400000000000002%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "IMAGE",
                    "title": "IMAGE",
                    "src": "/static/e930d39f80be4d1e06095637261ac5ec/85b06/blog-open-xtts-huggingface.png",
                    "srcSet": ["/static/e930d39f80be4d1e06095637261ac5ec/43fa5/blog-open-xtts-huggingface.png 250w", "/static/e930d39f80be4d1e06095637261ac5ec/85b06/blog-open-xtts-huggingface.png 256w"],
                    "sizes": "(max-width: 256px) 100vw, 256px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
          </div>
        </td>
      </tr>
    </table>
    <h3 {...{
      "id": "xtts-a-labour-of-love",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#xtts-a-labour-of-love",
        "aria-label": "xtts a labour of love permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`XTTS a Labour of Love`}</h3>
    <p>{`The foundation model XTTS is the culmination of years of work by the Coqui team and is able to
outperform both open and closed models in a broad range of tasks. For example:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Quality`}</strong>{` - XTTS generates speech that meets and exceeds `}<strong parentName="li">{`production-quality`}</strong>{` requirements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Multilingual`}</strong>{` - XTTS generates speech in `}<strong parentName="li">{`13 different languages`}</strong>{` (Arabic, Brazilian
Portuguese, Chinese, Czech, Dutch, English, French, German, Italian, Polish, Russian, Spanish,
and Turkish). More to come!`}</li>
      <li parentName="ul"><strong parentName="li">{`Voice Cloning`}</strong>{` - XTTS `}<strong parentName="li">{`clones any voice`}</strong>{` using only a small sample of the original voice,
i.e. you give a voice sample in German and you can create a clone that sounds like the
original voice speaking German.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cross Language Voice Cloning`}</strong>{` - XTTS `}<strong parentName="li">{`clones across languages`}</strong>{`, i.e. you give a voice
sample in German and you can create a clone that sounds like the original voice speaking any
of the other languages (Arabic, Brazilian Portuguese, Chinese, Czech, Dutch, English, French,
Italian, Polish, Russian, Spanish, Turkish).`}</li>
    </ul>
    <p>{`Coqui’s innovation is not limited to only the foundation model XTTS. Coqui is also innovating
in open model licensing. (Currently, open `}<em parentName="p">{`model`}</em>{` licensing, not open `}<em parentName="p">{`source`}</em>{` licensing,
is `}<em parentName="p">{`very`}</em>{` broken.)`}</p>
    <p>{`Working with `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/heathermeeker/"
      }}>{`Heather Meeker`}</a>{`, world-leading expert
on open source licenses, Coqui has created a new, innovative model license, the Coqui Public
Model License (CPML), and XTTS will be the first ever model released under the CPML! You can
read more about the Coqui Public Model License (CPML) `}<a parentName="p" {...{
        "href": "/cpml"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Enterprises, if you’re interested in licensing the model, fine-tuned versions of the model, or
other model variants under other licensing terms, just reach out `}<a parentName="p" {...{
        "href": "mailto:licensing@coqui.ai"
      }}>{`licensing@coqui.ai`}</a>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      